import { Link, useMatch } from "react-router-dom";
import { games } from "../../data/games";
import PlayVid from "../../assets/Play.mp4";

export default function Play() {
  return (
    <>
      <video
        className="full-screen-video-background"
        src={PlayVid}
        alt="Play Video"
        autoPlay
        muted
        loop
      />
      <div className="play-screen full-screen-and-padding">
        <h1 className="page-title text-glow">Our Game Collection</h1>
        <div class="games">
          {games.map((game, index) => (
            <div key={game.id} class="card">
              <div class="card-image-wrapper">
                <img
                  src={game.img}
                  class="card-img-top img-fluid"
                  alt="..."
                />
              </div>
              <div class="card-body">
                <h5 class="card-title">{game.title}</h5>
                <p class="card-text">{game.description}</p>
                <a target="_blank" href="https://drenko.itch.io/friendly-wasteland" class="play-btn">
                  Play
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="footer footer-flex-bottom">
          <div className="footer-top-part">
            <div>
              <Link
                to="/play"
                className="footer-primary translate-y-hover-for-play inactive-link "
              >
                PLAY
              </Link>
            </div>
          </div>
          <div className="footer-bottom-part">
            <Link
              to="/collections"
              className="footer-secondary translate-y-hover "
            >
              Collections
            </Link>

            <Link
              to="/prizes"
              className="footer-secondary translate-y-hover "
            >
              Prizes
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
