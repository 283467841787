export const prizes = [
  {
    label: "drenk0monster",
    points: "88",
  },
  {
    label: "Catto",
    points: "67",
  },
  {
    label: "Phantasmagoria",
    points: "55",
  },
  {
    label: "Starlord",
    points: "32",
  },
];
