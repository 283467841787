import { Link } from "react-router-dom";
import { collections } from "../../data/collections";
import CollectionsVid from "../../assets/Collections.mp4";

export default function Collections() {
  return (
    <>
      <video
        className="full-screen-video-background"
        src={CollectionsVid}
        alt="Collections Video"
        autoPlay
        muted
        loop
      />
      <div className="collections-screen full-screen-and-padding">
        <h1 className="text-center text-glow">Your collections</h1>
        <div className="collections">
          {collections.map((collection, idx) => {
            return (
              <div className="collection">
                <div>{idx + 1}.</div>
                <div className="label">{collection.label}</div>
                <div className="amount">
                  <span className="coin">{collection.coin}</span>
                  <span>{collection.amount}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="footer footer-flex-bottom">
          <div className="footer-top-part">
            <div>
              <Link
                to="/play"
                className="footer-primary translate-y-hover-for-play "
              >
                PLAY
              </Link>
            </div>
          </div>
          <div className="footer-bottom-part">
            <Link
              to="/collections"
              className="footer-secondary translate-y-hover inactive-link"
            >
              Collections
            </Link>

            <Link
              to="/prizes"
              className="footer-secondary translate-y-hover"
            >
              Prizes
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
