export const collections = [
  {
    label: "champ",
    amount: "0.553",
    coin: "B",
  },
  {
    label: "Catto",
    amount: "0.412",
    coin: "B",
  },
  {
    label: "drenk0monster",
    amount: "0.253",
    coin: "B",
  },
  {
    label: "Starlord",
    amount: "0.112",
    coin: "B",
  },
];
