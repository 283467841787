import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useMatch,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Play from "./pages/Play/Play";
import Prizes from "./pages/Prizes/Prizes";
import Collections from "./pages/Collections/Collections";
const router = createBrowserRouter([
  {
    element: <Home />,
    path: "/",
  },
  {
    path: "/collections",
    element: <Collections />,
  },
  {
    path: "/prizes",
    element: <Prizes />,
  },
  {
    path: "/play",
    element: <Play />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);
function App() {
  // find current route
  return (
    <div class="screen-container">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
