import React, { useRef, useEffect } from "react";
import homeVideo from "../../assets/Home.mp4";
import logoImage from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <video
        src={homeVideo}
        className="full-screen-video-background"
        autoPlay
        muted
        loop
      />
      <div className=" home-screen full-screen-and-padding ">
        <div className="header">
          <img
            className="header-logo"
            src={logoImage}
            alt="Arcade On Chain"
          />

          <span className="header-links">
            <a className="header-icon" href="https://twitter.com/arcadeonchain">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a className="header-icon" href="https://t.me/Arcadeonchain">
              <FontAwesomeIcon icon={faTelegram} />
            </a>
            <a className="header-icon" href="https://www.instagram.com/arcadeonchain/">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </span>
        </div>

        <div className="container">
          <div className="secondary-header">You Are On</div>
          <div className="primary-header"> Arcade On Chain</div>
          <div className="secondary-header">
            The New Gaming Experience
          </div>
        </div>

        <div className="footer">
          <div className="footer-top-part">
            <div>
              <Link
                to="/play"
                className="footer-primary translate-y-hover-for-play "
              >
                PLAY
              </Link>
            </div>
          </div>
          <div className="footer-bottom-part">
            <Link
              to="/collections"
              className="footer-secondary translate-y-hover"
            >
              Collections
            </Link>

            <Link
              to="/prizes"
              className="footer-secondary translate-y-hover"
            >
              Prizes
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
